import { graphql } from "gatsby"
import React from "react"
import { Appear, HTMLNode, SEO } from "../components"
import { HTMLNode as HTMLNodeModel } from "../models/HTMLNode"

interface Props {
  data: {
    page: {
      contentNode: HTMLNodeModel
      seoMetaTags: object
      title: string
    }
  }
}

const PrivacyPolicyPage = (props: Props) => {
  const { contentNode, seoMetaTags, title } = props.data.page

  return (
    <>
      <SEO seoMetaTags={seoMetaTags} />
      <div className="container">
        <h1 className="heading-l lg:heading-xl mb-10 lg:mb-16">
          <Appear order={1}>{title}</Appear>
        </h1>
        <div className="md:w-3/4 lg:w-1/2 paragraph-s html lg:mx-auto pb-32">
          <Appear order={2}>
            <HTMLNode contentNode={contentNode} />
          </Appear>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query PrivacyPolicyQuery($locale: String!) {
    page: datoCmsPrivacyPolicy(locale: { eq: $locale }) {
      title
      contentNode {
        ...HTMLNodeFragment
      }
      seoMetaTags {
        tags
      }
    }
  }
`
